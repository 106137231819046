<!-- MUIQ1 storage main component holding all others -->

<template lang="pug">
main.muiq-storage
  router-view
</template>

<script>
import Consts from '../js/constants.js'
import LoginPage from './LoginPage.vue'
import ApiHelper from '../js/ApiHelper.js'

export default {
  components: {
    [Consts.COMP_LOGIN_PAGE]: LoginPage
  },

  data () {
    return {
      apiHelper: null,
      boundOnAppClose: null
    }
  },

  created () {
    this.boundOnAppClose = this.onAppClose.bind(this)
    this.apiHelper = new ApiHelper(this.$BACKEND_ORIGIN, this.$http)
    window.addEventListener('beforeunload', this.boundOnAppClose)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.boundOnAppClose)
  },

  methods: {
    async onAppClose (ev) {
      if (!this.apiHelper.isRemembered() && this.apiHelper.hasToken()) {
        await this.apiHelper.logout()
        this.apiHelper.cleanOutdatedCredentials()
      }
    }
  }
}
</script>

<style lang="scss">
@import '../scss/core-styles.scss';

.muiq-storage {
  height: 100%;
}
</style>
