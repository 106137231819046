/**
 * Sentry-based logger for front-end
 */

export default class Logger {
  // Possible logging modes
  static LOG_ALL () { return 'all' }
  static LOG_ERR () { return 'error' }
  static LOG_OFF () { return 'off' }
  
  constructor (sentryInstance, logMode = this.LOGGING_ALL) {
    this.logMode = logMode
    this.sentry = sentryInstance
  }
  
  /**
   * Sends debug event
   *
   * @arg {string} msg
   * @arg {Object|string|number} [extraInfo] - arbitrary optional data
   */
  d (msg, extraInfo = null) {
    if (Logger.logMode !== this.LOG_ALL) return
    
    this.sentry.withScope(scope => {
      scope.setLevel('debug')
      this._handleExtraInfo(scope, extraInfo)
      
      this.sentry.captureMessage(msg)
    })
  }
  
  _isObject (obj) {
    let type = typeof obj
    
    return type === 'function' ||
      (type === 'object' && !!obj)
  }
  
  _handleExtraInfo (sentryScope, extraInfo) {
    if (extraInfo) {
      if (this._isObject(extraInfo)) {
        for (let extraKey in extraInfo) {
          if (extraInfo[extraKey] instanceof Buffer) {
            let bufStr = extraInfo[extraKey].toString('hex')
            
            sentryScope.setExtra(extraKey, bufStr)
          } else {
            sentryScope.setExtra(extraKey, extraInfo[extraKey])
          }
        }
      } else {
        sentryScope.setExtra('Message data', extraInfo)
      }
    }
  }
  
  /**
   * Sends info event
   *
   * @arg {string} msg
   * @arg {Object|string|number} [extraInfo] - arbitrary optional data
   */
  i (msg, extraInfo = null) {
    if (Logger.logMode !== this.LOG_ALL) return
    
    this.sentry.withScope(scope => {
      scope.setLevel('info')
      this._handleExtraInfo(scope, extraInfo)
      
      this.sentry.captureMessage(msg)
    })
  }
  
  /**
   * Sends warning event
   *
   * @arg {string} msg
   * @arg {Object|string|number} [extraInfo] - arbitrary optional data
   */
  w (msg, extraInfo = null) {
    if (Logger.logMode !== this.LOG_ALL) return
    
    this.sentry.withScope(scope => {
      scope.setLevel('warning')
      this._handleExtraInfo(scope, extraInfo)
      
      this.sentry.captureMessage(msg)
    })
  }
  
  /**
   * Sends error event
   *
   * @arg {Error} msg
   * @arg {Object|string|number} [extraInfo] - arbitrary optional data
   */
  e (msg, extraInfo = null) {
    if (Logger.logMode === this.LOG_OFF) return
    
    this.sentry.withScope(scope => {
      scope.setLevel('error')
      this._handleExtraInfo(scope, extraInfo)
      
      this.sentry.captureException(msg)
    })
  }
  
  /**
   * Sends fatal error event
   *
   * @arg {Error} msg
   * @arg {Object|string|number} [extraInfo] - arbitrary optional data
   */
  fe (msg, extraInfo = null) {
    if (Logger.logMode === this.LOG_OFF) return
    
    this.sentry.withScope(scope => {
      scope.setLevel('fatal')
      this._handleExtraInfo(scope, extraInfo)
      
      this.sentry.captureException(msg)
    })
  }
}
