/**
 * Used for doing all API calls and receiving results
 */

import Consts from './constants.js'

export default class ApiHelper {
  get HEADER_AUTH () { return 'Authorization' }
  
  constructor (backendOrigin, http) {
    this.http = http
    this.origin = backendOrigin
  }
  
  async loadStorageAppVersion () {
    let headers = this._prepareAuthHeader()
    let versionUrl = `${this.origin}${Consts.API_VERSION}`
    let version = await this.http.get(versionUrl, { headers })
    
    return version.body
  }
  
  async loadAllStats () {
    let headers = this._prepareAuthHeader()
    
    let spaceStatsUrl = `${this.origin}${Consts.API_SPACE_STATS}`
    let spaceStatsResp = await this.http.get(
      spaceStatsUrl,
      { headers }
    )
    
    let commonStatsUrl = `${this.origin}${Consts.API_COMMON_STATS}`
    let commonStatsResp = await this.http.get(
      commonStatsUrl,
      { headers }
    )
    
    let muiqsStatsUrl = `${this.origin}${Consts.API_MUIQ_INST_STATS}`
    let muiqsStatsResp = await this.http.get(
      muiqsStatsUrl,
      { headers }
    )
    
    return {
      spaceStats: spaceStatsResp.body,
      muiqsStats: muiqsStatsResp.body,
      commonStats: commonStatsResp.body
    }
  }
  
  _prepareAuthHeader () {
    let accessToken = this._getAccessToken()
    
    return { [this.HEADER_AUTH]: `Bearer ${accessToken}` }
  }
  
  _getAccessToken () {
    return window.localStorage.getItem(Consts.KEY_ACCESS_TOKEN)
  }
  
  cleanOutdatedCredentials () {
    window.localStorage.removeItem(Consts.KEY_ACCESS_TOKEN)
    window.localStorage.removeItem(Consts.KEY_NEED_REMEMBER)
  }
  
  isRemembered () {
    let needRemember = window.localStorage.getItem(
      Consts.KEY_NEED_REMEMBER
    )
    
    return needRemember === 'true'
  }
  
  hasToken () {
    let token = window.localStorage.getItem(Consts.KEY_ACCESS_TOKEN)
    
    return token !== null
  }
  
  async loadStorageInfo () {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_STORAGE_STATS}`
    let storageInfo = await this.http.get(apiUrl, { headers })
    
    return storageInfo.body
  }
  
  async loadAllMuiqInsts () {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}`
    let resp = await this.http.get(apiUrl, { headers })
    
    return resp.body.muiqInstances
  }
  
  async saveNewMuiqInst (muiqGid, domainName) {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}`
    let muiqInstData = {
      [Consts.FIELD_MUIQ_GID]: muiqGid,
      [Consts.FIELD_MUIQ_DOMAIN]: domainName
    }
    let resp = await this.http.post(apiUrl, muiqInstData, { headers })
    
    return resp.body.recordDbId
  }
  
  async loadOneMuiqInst (instDbId) {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}/${instDbId}`
    let newRecData = await this.http.get(apiUrl, { headers })
    
    return newRecData.body.muiqInstance
  }
  
  async deleteOneMuiqInst (instDbId) {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}/${instDbId}`
    let resp = await this.http.delete(apiUrl, { headers })
    
    return resp.status === 200
  }
  
  async updateOneMuiqInst (changedData) {
    let instDbId = changedData.id
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}/${instDbId}`
    let resp = await this.http.put(apiUrl, changedData, { headers })
    
    return resp.body
  }
  
  async reissueStorageApiToken (instDbId) {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_MUIQ_INSTANCES}/${instDbId}/reissue`
    let resp = await this.http.put(apiUrl, null, { headers })
    
    return resp.body.newStorageApiToken
  }
  
  async logout () {
    let headers = this._prepareAuthHeader()
    let apiUrl = `${this.origin}${Consts.API_LOGOUT}`
    
    await this.http.post(apiUrl, null, { headers })
  }
}
