<!-- Encapsulates login page functionality -->

<template lang="pug">
section.login-page
  .login-page__wrapper
    header.login-page__header
      img.login-page__logo(src='/public/images/muiq-logo-compact.png')
      h1.login-page__title MUIQ Storage
      
    article.login-page__login-form
      h4.login-page__form-caption Please log in
      
      .login-page__login-wrapper
        input.login-page__login(
          type='text'
          maxlength='64'
          placeholder='Username'
          :value='login'
          @input='onChangeLogin'
        )
        
      .login-page__pass-wrapper
        input.login-page__pass(
          type='password'
          maxlength='64'
          placeholder='Password'
          :value='pass'
          @input='onChangePass'
          @keyup.enter='onLoginBtnClick'
        )
        
      simple-checkbox.login-page__remember(
        :isChecked='needRemember'
        :checkChangeHandler='onRememberCheck'
      ) Remember me
      
      simple-button.login-page__login-btn(
        :clickHandler='onLoginBtnClick'
        :class='{"simple-btn--off": isLogging, "simple-btn--wait": isLogging}'
      ) Enter

      ul.login-page__errors(v-if='loginErrors.length > 0')
        li.login-page__err(v-for='err in loginErrors') {{ err }}
        
  .login-page__footer © MeasuringU, 2020
  
</template>

<script>
import Dayjs from 'dayjs'
import Consts from '../js/constants.js'
import SimpleButton from './SimpleButton.vue'
import { mapGetters, mapMutations } from 'vuex'
import SimpleCheckbox from './SimpleCheckbox.vue'
import RelativeTime from 'dayjs/plugin/relativeTime'

Dayjs.extend(RelativeTime)

export default {
  name: Consts.COMP_LOGIN_PAGE,
  
  components: {
    [Consts.COMP_SIMPLE_BUTTON]: SimpleButton,
    [Consts.COMP_SIMPLE_CHECKBOX]: SimpleCheckbox
  },

  data () {
    return {
      isLogging: false
    }
  },

  computed: {
    ...mapGetters([
      'pass',
      'login',
      'needRemember',
      'loginErrors'
    ])
  },

  methods: {
    ...mapMutations([
      'setPass',
      'setLogin',
      'setNeedRemember',
      'setLoginErrors'
    ]),

    onRememberCheck (isChecked) {
      this.setNeedRemember(isChecked)
    },

    async onLoginBtnClick () {
      let errors = this.checkValidationErrors()

      if (errors.length > 0) {
        this.setLoginErrors(errors)
        return
      } else {
        this.setLoginErrors([])
      }

      this.isLogging = true
      
      await this.tryLogin()
      
      this.isLogging = false
    },

    checkValidationErrors () {
      let errors = []

      if (this.login.length <= 0) {
        errors.push('Login should not be empty')
      }
      
      if (this.pass.length <= 0) {
        errors.push('Password should not be empty')
      }

      return errors
    },

    async tryLogin () {
      let apiUrl = `${this.$BACKEND_ORIGIN}${Consts.API_LOGIN}`
      let loginData = { login: this.login, pass: this.pass }

      try {
        let resp = await this.$http.post(apiUrl, loginData)

        if ('accessToken' in resp.body) {
          window.localStorage.setItem(
            Consts.KEY_ACCESS_TOKEN,
            resp.body.accessToken
          )
          window.localStorage.setItem(
            Consts.KEY_NEED_REMEMBER,
            this.needRemember
          )
          this.resetInputFields()
          this.$router.push(Consts.ROUTE_DASHBOARD)
        } else {
          this.setLoginErrors(['Oops, something went wrong'])
          this.$log.e(
            'No access token received on correct credentials',
            resp.body
          )
        }
      } catch (errResp) {
        let errMsg = `Server returned ${errResp.status} ${errResp.statusText} on request ${errResp.url}`
        this.$log.e(new Error(errMsg), errResp.body)
        
        if (errResp.status === 429) {
          let errMsg = this.makeTooManyRequestErrMsg(errResp.headers)
          this.setLoginErrors([errMsg])
        } else if (errResp.status >= 500 || errResp.status === 0) {
          this.setLoginErrors([
            'Server is unreachable, please try again later'
          ])
        } else {
          let err = errResp.body.err || 'Oops, something went wrong.'
          this.setLoginErrors([err])
        }
      }
    },

    makeTooManyRequestErrMsg (headers) {
      let whenTryAgain = 'later'

      if (headers.has('retry-after')) {
        let tryAgainTimeSec = parseInt(headers.get('retry-after'))
        let whenToTryDateSecs = (Date.now() / 1000) + tryAgainTimeSec
        let whentoTryDate = Dayjs.unix(whenToTryDateSecs)
        whenTryAgain = whentoTryDate.fromNow(false)
      }

      return `Too many login attempts, try again ${whenTryAgain}`
    },

    resetInputFields () {
      this.setPass('')
      this.setLogin('')
    },

    onChangePass (ev) {
      this.setPass(ev.target.value)
    },

    onChangeLogin (ev) {
      this.setLogin(ev.target.value)
    }
  }
}
</script>

<style lang="scss">
@import '../scss/fa-base';
@import '../scss/base/base';

.login-page {
  height: 100vh;
  display: grid;
  grid-template-rows: 20% 400px 1fr 25px;
  grid-template-columns: 1fr 350px 1fr;
  background-image: linear-gradient(to bottom, #fff, #e6ecf3);
  
  &__wrapper {
    display: flex;
    grid-row-end: 3;
    grid-row-start: 2;
    grid-column-end: 3;
    grid-column-start: 2;
    flex-direction: column;
  }
  &__header {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
  }
  &__logo {
    @include mix-square(50px);
    
    margin: 0 15px 0 0;
  }
  &__title {
    margin: 0;
    color: $colorSecondary;
  }
  &__login-form {
    display: flex;
    // border-top: 15px solid #4c8fd4;
    border-top: 10px solid #699dd2;
    border-radius: 3px;
    align-items: stretch;
    padding: 30px 25px 40px;
    flex-direction: column;
    // background-color: lighten($colorPrimary, 10%);
    background-image: linear-gradient(to bottom, #add4f9, #9ec3e6);
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.4),
      0 5px 15px 0 rgba(0, 0, 0, 0.2);
  }
  &__form-caption {
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  &__login-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;

    &:before {
      @include mix-square(20px);

      top: 50%;
      left: 12px;
      display: block;
      color: #699dd2;
      position: absolute;
      transform: translateY(-40%);
      content: fa-content($fa-var-user);
      font-family: $fontFamilyAwesome;
    }
  }
  &__pass, &__login {
    border: none;
    display: flex;
    line-height: 1;
    padding: 5px 10px 5px 40px;
    border-radius: $borderRadiusBase;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    &:focus {
      outline: none;
    }
  }
  &__pass-wrapper {
    display: flex;
    margin-top: 15px;
    position: relative;
    flex-direction: column;
    
    &:before {
      @include mix-square(20px);

      top: 50%;
      left: 12px;
      display: block;
      color: #699dd2;
      position: absolute;
      transform: translateY(-40%);
      content: fa-content($fa-var-key);
      font-family: $fontFamilyAwesome;
    }
  }
  &__remember {
    color: #fff;
    display: block;
    margin-top: 25px;
  }
  &__login-btn {
    width: 100%;
    display: block;
    font-size: 13px;
    min-height: 30px;
    margin-top: 35px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    box-shadow:
      0 1px 1px 0 rgba(0, 0, 0, 0.3),
      0 1px 4px 0 rgba(0, 0, 0, 0.2);
  }
  &__errors {
    color: #fff;
    margin: 20px 0 0 0;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding-left: 20px;
    text-shadow: 0 1px 1px #426cb8;
    
    &:before {
      @include mix-square(15px);

      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block;
      font-family: $fontFamilyAwesome;
      content: fa-content($fa-var-exclamation-triangle);
    }
  }
  &__err {
    margin: 0;
    color: #fff;
    height: 15px;
    font-size: 13px;
    margin-top: 5px;
    text-align: center;
    list-style-type: none;
  }
  &__footer {
    color: #fff;
    display: flex;
    font-size: 13px;
    grid-row-end: 5;
    grid-row-start: 4;
    grid-column-end: 4;
    align-items: center;
    grid-column-start: 1;
    justify-content: center;
    background-color: $colorSecondary;
  }
}

</style>
