/**
 * MUIQ1 Storage SPA routes
 */

import Consts from './constants.js'
import ApiHelper from './ApiHelper.js'
import LoginPage from '../components/LoginPage.vue'

export default class RouteHelper {
  /**
   * @arg {string} backendOrigin - origin for backend API requests
   * @arg {Object} http - instance of Vue-resource
   * @arg {Object} vuexStore
   * @arg {Object} logger
   */
  constructor (backendOrigin, http, vuexStore, logger) {
    this.http = http
    this.log = logger
    this.store = vuexStore
    this.origin = backendOrigin
    this.apiHelper = new ApiHelper(this.origin, this.http)
  }
  
  /**
   * Prepares routes config suitable for Vue-router
   */
  makeRoutes () {
    return [
      this._makeLoginPageRoute(),
      this._makeDashboardRoute(),
      this._makeMuiqInstancesPageRoute()
    ]
  }
  
  _makeLoginPageRoute () {
    return {
      component: LoginPage,
      path: Consts.ROUTE_ROOT,
      beforeEnter: this._onLoginPageBeforeEnter.bind(this)
    }
  }
  
  _onLoginPageBeforeEnter (to, from, next) {
    let token = window.localStorage.getItem(
      Consts.KEY_ACCESS_TOKEN
    )
    
    if (token === null) {
      next()
    } else {
      if (from.path === Consts.ROUTE_DASHBOARD) {
        next(false)
      } else {
        next(Consts.ROUTE_DASHBOARD)
      }
    }
  }
  
  _makeDashboardRoute () {
    return {
      component: () => import(
        /* webpackChunkName: "admin-page" */
        '../components/AdminPage.vue'
      ),
      path: Consts.ROUTE_DASHBOARD,
      children: [this._makeDashboardSubRoute()]
    }
  }
  
  _makeDashboardSubRoute () {
    return {
      path: '',
      component: () => import(
        /* webpackChunkName: "muiqs-dashboard" */
        '../components/MuiqsDashboard.vue'
      ),
      beforeEnter: this._onDashboardBeforeEnter.bind(this)
    }
  }
  
  async _onDashboardBeforeEnter (to, from, next) {
    try {
      let allRawStats = await this.apiHelper.loadAllStats()
      this.store.commit('setAllRawStats', { allRawStats })
      
      next()
    } catch (errResp) {
      this.apiHelper.cleanOutdatedCredentials()
      let errMsg = `Server returned ${errResp.status} ${errResp.statusText} on request ${errResp.url}`
      this.log.e(new Error(errMsg))
      next(Consts.ROUTE_ROOT)
    }
  }
  
  _makeMuiqInstancesPageRoute () {
    return {
      component: () => import(
        /* webpackChunkName: "admin-page" */
        '../components/AdminPage.vue'
      ),
      path: Consts.ROUTE_MUIQ_INSTANCES,
      children: [this._makeMuiqIntancesSubRoute()]
    }
  }
  
  _makeMuiqIntancesSubRoute () {
    return {
      path: '',
      component: () => import(
        /* webpackChunkName: "muiq-instances" */
        '../components/MuiqInstances.vue'
      ),
      beforeEnter: this._onMuiqInstancesBeforeEnter.bind(this)
    }
  }
  
  async _onMuiqInstancesBeforeEnter (to, from, next) {
    try {
      let allRawMuiqInsts = await this.apiHelper.loadAllMuiqInsts()
      this.store.dispatch('updateAllMuiqInstances', allRawMuiqInsts)
      
      let storageInfo = await this.apiHelper.loadStorageInfo()
      this.store.commit('setStorageInfo', { ...storageInfo })
      
      next()
    } catch (errResp) {
      this.apiHelper.cleanOutdatedCredentials()
      let errMsg = `Server returned ${errResp.status} ${errResp.statusText} on request ${errResp.url}`
      this.log.e(new Error(errMsg))
      next(Consts.ROUTE_ROOT)
    }
  }
}
