<!-- Encapsulates simple checkbox functionality -->

<template lang="pug">
button.simple-checkbox(
  type='button'
  :class='checkboxCssClass'
  @click='onClick'
  :disabled='isDisabled'
)
  slot
</template>

<script>
import Consts from '../js/constants.js'

const CSS_CLASS_NAME = 'simple-checkbox'

export default {
  name: Consts.COMP_SIMPLE_CHECKBOX,

  props: {
    isChecked: {
      type: Boolean,
      required: true,
      default: false
    },

    checkChangeHandler: {
      type: Function,
      required: true
    },

    /**
     * Different UI appearance for the checkbox
     *
     * - normal
     * - inverse
     */
    uiStyle: {
      type: String,
      default: 'normal'

    },

    checkboxId: {
      type: String,
      default: ''
    },
    
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    checkboxCssClass: function () {
      let cssClasses = []

      if (this.isChecked) {
        if (this.uiStyle === 'inverse') {
          cssClasses.push(`${CSS_CLASS_NAME}--inverse-checked`)
        } else {
          cssClasses.push(`${CSS_CLASS_NAME}--checked`)
        }
      }

      if (this.isDisabled) {
        cssClasses.push(`${CSS_CLASS_NAME}--off`)
      }

      return cssClasses
    }
  },

  methods: {
    onClick: function () {
      if (this.checkboxId === '') {
        this.checkChangeHandler(!this.isChecked)
      } else {
        this.checkChangeHandler(!this.isChecked, this.checkboxId)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../scss/base/base.scss';

.simple-checkbox {
  @include mix-checkbox();
}
</style>
