var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "btn",
      staticClass: "simple-btn",
      class: _vm.customClass,
      attrs: { type: "button" },
      on: { click: _vm.onClick }
    },
    [
      !_vm.htmlContent
        ? _vm._t("default")
        : _c("div", { domProps: { innerHTML: _vm._s(_vm.htmlContent) } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }