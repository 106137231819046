/**
 * Shared constants
 */

export default {
  // Component names
  COMP_LOGIN_PAGE: 'login-page',
  COMP_ADMIN_PAGE: 'admin-page',
  COMP_DASHBOARD: 'muiqs-dashboard',
  COMP_SIMPLE_BUTTON: 'simple-button',
  COMP_SIMPLE_CHECKBOX: 'simple-checkbox',
  COMP_SPACE_PIE_CHART: 'space-pie-chart',
  COMP_MUIQ_INSTACES: 'muiq-instances',

  // App route paths
  ROUTE_ROOT: '/',
  ROUTE_DASHBOARD: '/dashboard',
  ROUTE_MUIQ_INSTANCES: '/muiqinstances',

  // Local storage keys
  KEY_ACCESS_TOKEN: 'muiqs_aat',
  KEY_NEED_REMEMBER: 'needRemember',
  
  // API request timeouts
  TIMEOUT_STATS_API: 15 * 1000,

  // API URL paths
  API_BASE: '/api/v1',
  API_VERSION: '/api/v1/version',
  API_LOGIN: '/api/v1/auth/login',
  API_LOGOUT: '/api/v1/auth/logout',
  API_SPACE_STATS: '/api/v1/stats/space',
  API_COMMON_STATS: '/api/v1/stats/common',
  API_STORAGE_STATS: '/api/v1/stats/storage',
  API_MUIQ_INST_STATS: '/api/v1/stats/muiqinst',
  API_MUIQ_INSTANCES: '/api/v1/muiqinstances',

  // MUIQ instance record fields
  FIELD_MUIQ_GID: 'globalId',
  FIELD_MUIQ_DOMAIN: 'domainName',
  FIELD_MUIQ_TOKEN: 'muiqApiToken',
  FIELD_FILES_NUM: 'storedFilesNum',
  FIELD_STORAGE_TOKEN: 'storageApiToken',
  
  // JWT token types
  JWT_MUIQ_API: 'muiqApi'
}
