/**
 * A plugin for installing Sentry-based logger as global property of
 * all Vue components
 */

import Logger from './Logger.js'

export default {
  /**
   * Main plugin installing method
   *
   * @arg {Function} Vue
   * @arg {Object} options - plugin options
   * @arg {Object} options.sentry - an instance of Sentry
   * @arg {Object} [options.logMode] - what to log (default 'all')
   */
  install (Vue, options) {
    if (!('sentry' in options)) {
      throw new Error('Sentry instance should be passed in "sentry" property of plugin options')
    }
    
    let logMode = Logger.LOG_ALL

    if ('logMode' in options) {
      logMode = options.logMode
    }
    
    let logger = new Logger(options.sentry, logMode)
    
    Vue.log = logger
    Vue.prototype.$log = logger
  }
}
