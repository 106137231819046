/**
 * Shared data for whole Vue app
 */

import Vue from 'vue'

export default {
  state: {
    /**
     * Global stuff
     */
    clipboardText: '',

    /**
     * Login page
     */
    pass: '',
    login: '',
    needRemember: false,
    loginErrors: [],
    
    /**
     * Dashboard page
     */
    allRawStats: null,

    /**
     * MUIQ instances page
     */
    muiqInsts: {},
    storageGid: '',
    storageDomain: '',
    editedInstDbId: null,
    editedFieldName: null,
    // Used for generation of unique temporary IDs for new MUIQ
    // instances bofore they are saved to DB
    tmpMuiqInstIdCounter: 1
  },

  getters: {
    /**
     * Global stuff
     */
    clipboardText: state => state.clipboardText,
    
    /**
     * Login page
     */
    pass: state => state.pass,
    login: state => state.login,
    needRemember: state => state.needRemember,
    loginErrors: state => state.loginErrors,
    
    /**
     * Dashboard page
     */
    allRawStats: state => state.allRawStats,
    
    /**
     * MUIQ instances page
     */
    muiqInsts: state => state.muiqInsts,
    storageGid: state => state.storageGid,
    storageDomain: state => state.storageDomain,
    editedInstDbId: state => state.editedInstDbId,
    editedFieldName: state => state.editedFieldName
  },

  mutations: {
    /**
     * Global stuff
     */
    setClipboardText (state, text) {
      state.clipboardText = text
    },

    /**
     * Login page
     */
    setPass (state, pass) {
      state.pass = pass
    },

    setLogin (state, login) {
      state.login = login
    },

    setNeedRemember (state, needRemember) {
      state.needRemember = needRemember
    },

    setLoginErrors (state, errors) {
      state.loginErrors = errors
    },
    
    /**
     * Dashboard page
     */
    setAllRawStats (state, allRawStats) {
      state.allRawStats = allRawStats
    },
    
    /**
     * MUIQ instances page
     */
    setStorageInfo (state, info) {
      state.storageGid = info.gid
      state.storageDomain = info.domain
    },
    
    addEmptyMuiqInst (state, id) {
      let emptyMuiqInstData = {
        /**
         * DB-based fields
         */
        id,
        globalId: '',
        domainName: '',
        muiqApiToken: '',
        storedFilesNum: 0,
        storageApiToken: '',
        createAt: Date.now(),
        
        /**
         * Custom fields used for UI
         */
        // Determines when MUIQ instance has expanded UI
        isOpen: true,
        // Instance has been never saved to DB yet
        isNew: true,
        // All recent changes are saved or not yet saved
        isSaved: false,
        fieldErrors: {},
        muiqApiTokenMasked: '',
        // Instance is in process of saving
        isSavingInProcess: false,
        storageApiTokenMasked: '',
        // Indicates that MUIQ storage token re-issuing is in process
        isReissuing: false
      }
      
      Vue.set(state.muiqInsts, id, emptyMuiqInstData)
    },
    
    deleteMuiqInst (state, id) {
      Vue.delete(state.muiqInsts, id)
    },
    
    setFieldError (state, { muiqDbId, fieldName, error }) {
      Vue.set(
        state.muiqInsts[muiqDbId].fieldErrors,
        fieldName,
        error
      )
    },
    
    deleteFieldError (state, { muiqDbId, fieldName }) {
      Vue.delete(
        state.muiqInsts[muiqDbId].fieldErrors,
        fieldName
      )
    },
    
    updateOneMuiqInst (state, { oldMuiqInstDbId, newMuiqInstData }) {
      let oldInst = state.muiqInsts[oldMuiqInstDbId]
      Vue.set(oldInst, 'isNew', false)
      Vue.set(oldInst, 'isSaved', true)
          
      for (let newKey in newMuiqInstData) {
        if (newKey === 'id') continue
        
        let isEditedField = newKey === state.editedFieldName &&
          oldMuiqInstDbId === state.editedInstDbId
          
        if (isEditedField) continue
        
        let newVal = newMuiqInstData[newKey]
        
        if (oldInst[newKey] !== newVal) {
          Vue.set(oldInst, newKey, newVal)
        }
      }
    },
    
    addNewMuiqInst (state, newMuiqInstData) {
      let tmpData = {}
      Object.assign(tmpData, newMuiqInstData)
      tmpData.isNew = false
      tmpData.isSaved = true
      tmpData.fieldErrors = {}
      tmpData.isSavingInProcess = false
      tmpData.muiqApiTokenMasked = newMuiqInstData.muiqApiToken
        ? newMuiqInstData.muiqApiToken.replace(/./g, '•')
        : ''
      tmpData.storageApiTokenMasked = newMuiqInstData.storageApiToken
        ? newMuiqInstData.storageApiToken.replace(/./g, '•')
        : ''

      Vue.set(state.muiqInsts, tmpData.id, tmpData)
    },
    
    incTmpMuiqIdCounter (state) {
      state.tmpMuiqInstIdCounter += 1
    },

    maskToken (state, { muiqDbId, tokenField }) {
      let origToken = state.muiqInsts[muiqDbId][tokenField]

      if (origToken) {
        let maskedToken = origToken.replace(/./g, '•')
        let maskedFieldName = `${tokenField}Masked`
        
        Vue.set(state.muiqInsts[muiqDbId], maskedFieldName, maskedToken)
      }
    },

    unmaskToken (state, { muiqDbId, tokenField }) {
      let origToken = state.muiqInsts[muiqDbId][tokenField]

      if (origToken) {
        let maskedFieldName = `${tokenField}Masked`
        
        Vue.set(state.muiqInsts[muiqDbId], maskedFieldName, origToken)
      }
    },

    setEditedField (state, { instDbId, fieldName }) {
      state.editedInstDbId = instDbId
      state.editedFieldName = fieldName
    },

    clearEditedField (state) {
      state.editedInstDbId = null
      state.editedFieldName = null
    }
  },

  actions: {
    /**
     * MUIQ instances page
     */
    makeNewTmpMuiqId ({ state, commit }) {
      commit('incTmpMuiqIdCounter')
      
      return `tmpid-${state.tmpMuiqInstIdCounter}`
    },
    
    async addNewEmptyMuiqInst ({ state, commit, dispatch }) {
      let newId = await dispatch('makeNewTmpMuiqId')
      commit('addEmptyMuiqInst', newId)
    },
    
    updateMuiqInstField ({ state, commit }, updData) {
      Vue.set(
        state.muiqInsts[updData.muiqDbId],
        updData.fieldName,
        updData.newValue
      )
      
      let isTokenField = updData.fieldName === 'muiqApiToken' ||
        updData.fieldName === 'storageApiToken'
        
      if (isTokenField) {
        let maskedFieldName = `${updData.fieldName}Masked`
        let maskedValue = updData.newValue
          ? updData.newValue.replace(/./g, '•')
          : ''

        Vue.set(
          state.muiqInsts[updData.muiqDbId],
          maskedFieldName,
          maskedValue
        )
      }
    },
    
    updateAllMuiqInstances ({ state, commit }, newInsts) {
      newInsts.forEach(newInst => {
        let newInstDbId = newInst.id
        
        if (newInstDbId in state.muiqInsts) {
          commit('updateOneMuiqInst', {
            oldMuiqInstDbId: newInstDbId,
            newMuiqInstData: newInst
          })
        } else {
          let found = false
          let newInstGid = newInst.globalId
          
          for (let oldInstDbId in state.muiqInsts) {
            let oldInst = state.muiqInsts[oldInstDbId]

            if (oldInst.globalId === newInstGid) {
              found = true
              
              commit('updateOneMuiqInst', {
                oldMuiqInstDbId: oldInst.id,
                newMuiqInstData: newInst
              })
              
              break
            }
          }
          
          if (!found) {
            commit('addNewMuiqInst', newInst)
          }
        }
      })
    },

    isMuiqGidUnique ({ state }, muiqGid) {
      let matches = 0
      
      for (let instDbId in state.muiqInsts) {
        let muiqInst = state.muiqInsts[instDbId]
        
        if (muiqInst.globalId === muiqGid) {
          matches += 1
        }
      }
      
      return matches === 1
    }
  }
}
