/**
 * Main JS entry file for MUIQ Storage SPA
 */

import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import App from '../components/App.vue'
import * as Sentry from '@sentry/browser'
import RouteHelper from '../js/RouteHelper.js'
import MuiqsVuexStore from './vuex/muiqs-store.js'
import LoggerPlugin from '../js/SentryLoggerPlugin.js'

/* global MUIQ_ENV, MUIQS_BACKEND_ORIGIN, SENTRY_DSN, MUIQ_STORAGE_VERSION */

let sentryDsn = SENTRY_DSN
let appVersion = MUIQ_STORAGE_VERSION
let backendOrigin = MUIQS_BACKEND_ORIGIN

Sentry.init({ dsn: sentryDsn, attachStacktrace: true })

// Enabling plugins
Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(LoggerPlugin, { sentry: Sentry, logMode: 'all' })

// Injecting global environment variables into any component
Vue.use((Vue) => { Vue.prototype.$MUIQ_ENV = MUIQ_ENV })
Vue.use((Vue) => { Vue.prototype.$APP_VERSION = appVersion })
Vue.use((Vue) => { Vue.prototype.$BACKEND_ORIGIN = backendOrigin })

// Creating Vuex store
const store = new Vuex.Store(MuiqsVuexStore)

// Configuring routing for SPA
const routeHelper = new RouteHelper(
  backendOrigin,
  Vue.http,
  store,
  Vue.log
)
const router = new VueRouter({
  mode: 'history',
  routes: routeHelper.makeRoutes()
})

// Mounting App component
// eslint-disable-next-line
new Vue({ el: '#app', router, store, render: h => h(App) })
