<!-- Encapsulates simple button functionality -->

<template lang="pug">
button.simple-btn(
  ref='btn'
  :class='customClass'
  type='button'
  @click='onClick'
)
  slot(v-if='!htmlContent')
  div(v-else v-html='htmlContent')
</template>

<script>
import Consts from '../js/constants.js'

const CSS_CLASS_NAME = 'simple-btn'

export default {
  name: Consts.COMP_SIMPLE_BUTTON,

  props: {
    /**
     * Called when a button was pressed
     *
     * @arg {string} buttonId
     * @arg {object} HTML node associated with a button
     */
    clickHandler: {
      type: Function,
      required: true
    },

    /**
     * Used to determine which button was pressed
     */
    id: {
      type: [String, Number],
      default: ''
    },

    /**
     * Different UI styles of button look, allowed variants are:
     *
     * - normal
     * - contour
     */
    uiStyle: {
      type: String,
      default: 'normal'
    },

    /**
     * Button state, can be one of following:
     *
     * - normal
     * - disabled
     * - waiting (disabled with loading spinner icon)
     */
    state: {
      type: String,
      default: 'normal'
    },

    htmlContent: {
      type: String,
      default: null
    }
  },

  computed: {
    customClass: function () {
      let cssClass = []

      if (this.uiStyle !== 'normal') {
        cssClass.push(`${CSS_CLASS_NAME}--${this.uiStyle}`)
      }

      if (this.isDisabled || this.isWaiting) {
        cssClass.push(`${CSS_CLASS_NAME}--off`)
      }

      if (this.isWaiting) {
        cssClass.push(`${CSS_CLASS_NAME}--wait`)
      }

      return cssClass
    },

    isDisabled: function () {
      return this.state === 'disabled'
    },

    isWaiting: function () {
      return this.state === 'waiting'
    },

    isNormal: function () {
      return this.state === 'normal'
    }
  },

  methods: {
    onClick: function () {
      if (this.clickHandler && this.isNormal) {
        this.clickHandler(this.id, this.$refs.btn)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../scss/base/base.scss';

.simple-btn {
  @include mix-button(200px, 30px, $colorAccent, $needShadow: true);
}
</style>
