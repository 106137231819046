var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "login-page" }, [
    _c("div", { staticClass: "login-page__wrapper" }, [
      _vm._m(0),
      _c(
        "article",
        { staticClass: "login-page__login-form" },
        [
          _c("h4", { staticClass: "login-page__form-caption" }, [
            _vm._v("Please log in")
          ]),
          _c("div", { staticClass: "login-page__login-wrapper" }, [
            _c("input", {
              staticClass: "login-page__login",
              attrs: { type: "text", maxlength: "64", placeholder: "Username" },
              domProps: { value: _vm.login },
              on: { input: _vm.onChangeLogin }
            })
          ]),
          _c("div", { staticClass: "login-page__pass-wrapper" }, [
            _c("input", {
              staticClass: "login-page__pass",
              attrs: {
                type: "password",
                maxlength: "64",
                placeholder: "Password"
              },
              domProps: { value: _vm.pass },
              on: {
                input: _vm.onChangePass,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onLoginBtnClick($event)
                }
              }
            })
          ]),
          _c(
            "simple-checkbox",
            {
              staticClass: "login-page__remember",
              attrs: {
                isChecked: _vm.needRemember,
                checkChangeHandler: _vm.onRememberCheck
              }
            },
            [_vm._v("Remember me")]
          ),
          _c(
            "simple-button",
            {
              staticClass: "login-page__login-btn",
              class: {
                "simple-btn--off": _vm.isLogging,
                "simple-btn--wait": _vm.isLogging
              },
              attrs: { clickHandler: _vm.onLoginBtnClick }
            },
            [_vm._v("Enter")]
          ),
          _vm.loginErrors.length > 0
            ? _c(
                "ul",
                { staticClass: "login-page__errors" },
                _vm._l(_vm.loginErrors, function(err) {
                  return _c("li", { staticClass: "login-page__err" }, [
                    _vm._v(_vm._s(err))
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "login-page__footer" }, [
      _vm._v("© MeasuringU, 2020")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "login-page__header" }, [
      _c("img", {
        staticClass: "login-page__logo",
        attrs: { src: "/public/images/muiq-logo-compact.png" }
      }),
      _c("h1", { staticClass: "login-page__title" }, [_vm._v("MUIQ Storage")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }